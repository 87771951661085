import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import * as Yup from "yup";
import Container from "@components/styled/Container";
import Layout from "@components/layout";
import SEO from "@components/seo";
import SelectInput from "@components/Select";
import TextInput from "@components/TextInput";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Columns from "@components/styled/Columns";
import Column from "@components/styled/Column";
import Loading from "@components/Loading";
import get from "lodash/get";
import { base } from "@helpers/airtable";
import { isBrowser } from "@helpers/ssr";
import {
  FormHead,
  Form,
  FormSection,
  FieldGroup,
  FormActions,
} from "@components/Form";
import { authCheckRedirect } from "@helpers/auth";
import Back from "@components/Back";
const initialFormState = {
  stat_id: "",
  stat_type: "",
  stat_period: "",
};

const validationSchema = Yup.object().shape({
  stat_type: Yup.string().required("This is required"),
  stat_period: Yup.string().required("This is required"),
});

const EditStatPage = ({ user, data, ...props }) => {
  const [submissionError, setSubmissionError] = useState(false);
  const [webStatOptions, setWebStatOptions] = useState([]);
  const [downloadStatOptions, setDownloadStatOptions] = useState([]);
  const [subsSocialStatOptions, setSubsSocialStatOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState(initialFormState);
  const [periodOptions, setPeriodOptions] = useState({
    Web: [],
    Downloads: [],
    "Social/Sub": [],
  });
  const [allRecords, setAllRecords] = useState(null);

  function getExistingRecordData(existingRecords) {
    const urlParams = new URLSearchParams(window.location.search);
    const statPeriod = urlParams.get("period");
    const statType = urlParams.get("type");

    if (statPeriod !== "null" && statType && existingRecords) {
      const currentRecord = existingRecords[statType].filter(
        ({ fields }) => fields["Month-Year"] === statPeriod.trim()
      )[0];
      setFormState({
        ...formState,
        stat_id: currentRecord.id,
        stat_type: statType,
        stat_period: statPeriod.trim(),
        ...currentRecord.fields,
      });
    }
  }

  async function getAllRecords() {
    const results = await Promise.all([
      await base("Web Stat")
        .select({
          view: "Grid view",
        })
        .all(),
      await base("SubsSocial Stat")
        .select({
          view: "Grid view",
        })
        .all(),
      await base("Downloads Stat")
        .select({
          view: "Grid view",
        })
        .all(),
    ]);

    const allStats = {
      Web: results[0],
      SubsSocial: results[1],
      Downloads: results[2],
    };

    return allStats;
  }

  useEffect(() => {
    getExistingRecordData(allRecords);
  }, [isBrowser ? window.location.search : null]);

  useEffect(() => {
    authCheckRedirect();

    const fetchAllStats = async () => {
      const mapRecordOptions = (record) => {
        const options = [];
        for (let key in record.fields) {
          if (
            key !== "Month-Year" &&
            key !== "Annual Summary" &&
            key !== "National View" &&
            key !== "Annual Link" &&
            key !== "Sum"
          ) {
            options.push({
              label: key,
              value: record.fields[key],
            });
          }
        }

        return options;
      };

      const allStats = await getAllRecords();
      setAllRecords(allStats);

      const mapPeriodOptions = ({ fields }) => ({
        value: fields["Month-Year"] ? fields["Month-Year"].trim() : "",
        label: fields["Month-Year"],
      });

      setPeriodOptions({
        ...periodOptions,
        Web: allStats.Web.map(mapPeriodOptions),
        SubsSocial: allStats.SubsSocial.map(mapPeriodOptions),
        Downloads: allStats.Downloads.map(mapPeriodOptions),
      });

      setWebStatOptions(mapRecordOptions(Object.assign(allStats.Web[0])));
      setDownloadStatOptions(
        mapRecordOptions(Object.assign(allStats.Downloads[0]))
      );
      setSubsSocialStatOptions(
        mapRecordOptions(Object.assign(allStats.SubsSocial[0]))
      );

      getExistingRecordData(allStats);

      setLoading(false);
    };

    fetchAllStats();
  }, []);

  const statTypeOptions = [
    {
      value: "Downloads",
      label: "Downloads",
    },
    {
      value: "SubsSocial",
      label: "Sub & Socials",
    },
    {
      value: "Web",
      label: "Web",
    },
  ];

  const createStat = async (values) => {
    const details = { ...values };

    delete details.stat_type;
    delete details.stat;
    delete details.stat_id;
    delete details.stat_period;
    delete details["Month-Year"];
    delete details["Annual Summary"];
    delete details["National View"];
    delete details["Annual Link"];
    delete details["Sum"];

    return base(values.stat_type + " Stat").update([
      {
        id: values.stat_id,
        fields: details,
      },
    ]);
  };

  const handleSubmit = async (values, actions) => {
    try {
      await createStat(values);
      actions.setSubmitting(false);
      navigate("/");
    } catch (e) {
      setSubmissionError(true);
      actions.setSubmitting(false);
      console.log(e, "error");
    }
  };

  const getStatOptions = (formikValues) => {
    if (!formikValues) return [];

    if (!formikValues.stat_type) return [];

    if (formikValues.stat_type === "Downloads") {
      return downloadStatOptions;
    }
    if (formikValues.stat_type === "SubsSocial") {
      return subsSocialStatOptions;
    }
    if (formikValues.stat_type === "Web") {
      return webStatOptions;
    }
  };

  return (
    <Layout uri={props.uri}>
      <SEO title="Update stats" />
      <Container pt={[7]}>
        <section>
          <Back to="/" />
          <FormHead title="Update stats" />
          {loading ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              initialValues={formState}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                submitForm,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                resetForm,
                values,
                errors,
                touched,
              }) => (
                <Form>
                  <FormSection title="Basics">
                    <Columns>
                      <Column width={[1, 0.5]}>
                        <FieldGroup required name="stat_type" label="Stat type">
                          <SelectInput
                            name="stat_type"
                            value={values.stat_type}
                            onChange={(field, value) => {
                              setFormState({
                                ...initialFormState,
                                stat_type: value,
                              });
                              resetForm({
                                stat_type: value,
                                stat: "",
                                stat_period: "",
                              });
                              navigate(`/stat/?period=null&type=${value}`);
                            }}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "stat_type") &&
                              get(errors, "stat_type") &&
                              get(errors, "stat_type")
                            }
                            options={statTypeOptions}
                          />
                        </FieldGroup>
                      </Column>

                      <Column width={[1, 0.5]}>
                        {values.stat_type && (
                          <FieldGroup
                            required
                            name="stat_period"
                            label="Period"
                          >
                            <SelectInput
                              name="stat_period"
                              value={values.stat_period}
                              onChange={(name, value) => {
                                navigate(
                                  `/stat/?period=${value.trim()}&type=${
                                    values.stat_type
                                  }`
                                );

                                setFieldValue("stat_period", value);
                              }}
                              onBlur={setFieldTouched}
                              error={
                                get(touched, "stat_period") &&
                                get(errors, "stat_period") &&
                                get(errors, "stat_period")
                              }
                              options={periodOptions[values.stat_type]}
                            />
                          </FieldGroup>
                        )}
                      </Column>
                    </Columns>
                  </FormSection>
                  {values.stat_type && values.stat_period && (
                    <FormSection title="Details">
                      <Columns>
                        {getStatOptions(values).map((stat) => (
                          <Column width={[1]}>
                            <FieldGroup name={stat.label} label={stat.label}>
                              <TextInput
                                type="number"
                                name={stat.label}
                                value={values[stat.label]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FieldGroup>
                          </Column>
                        ))}
                      </Columns>
                    </FormSection>
                  )}
                  <FormActions
                    ctaLabel="Create"
                    onSubmitClick={submitForm}
                    onClearClick={() => resetForm(initialFormState)}
                    submitDisabled={isSubmitting}
                    error={submissionError}
                  />
                </Form>
              )}
            </Formik>
          )}
        </section>
      </Container>
    </Layout>
  );
};

export default EditStatPage;
